<section class="support_area ptb-100 bg-grey">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 wow slideInLeft animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div class="support_box_wrap mb-30">
                    <div class="single_item">
                        <div class="item">
                            <i class="flaticon-application"></i>
                            <span>web application</span>
                        </div>
                    </div>
                    <div class="single_item">
                        <div class="item">
                            <i class="flaticon-question"></i>
                            <span>FAQ/Knowledge</span>
                        </div>
                        <div class="item">
                            <div class="logo">
                                <img src="assets/images/icon/logo_icon.png" alt="">
                            </div>
                        </div>
                        <div class="item">
                            <i class="flaticon-24-hours-support"></i>
                            <span>24/7 support</span>
                        </div>
                    </div>
                    <div class="single_item">
                        <a class="item" href="https://www.cosys.com.np/" target="_blank">
                            <i class="flaticon-software"></i>
                            <span>cooperative software</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="support_content">
                    <div class="sec_title">
                        <span class="sub-line">Support & Service</span>
                        <h2 class="st_main">Delivering Innovation Goals.</h2>
                        <p>Our online support through email, project management software and messenger is a robust and flexible turn-key solution, allowing you to implement effective support channels, e-mail management and manage self-help resources. Online Support does this by combining ticketed support (web and e-mail based), live chat and an intuitive customer interface.</p>
                    </div>
                    <ul class="support_list mb-4">
                        <li><i class="pi pi-check"></i> View software & web application features</li>
                        <li><i class="pi pi-check"></i> Request for a demo</li>
                        <li><i class="pi pi-check"></i> Search our FAQ/Knowledge Base</li>
                    </ul>
                    <div class="support_btn">
                        <a class="default-btn btn-theme" href="#">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
