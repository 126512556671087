<section class="our-services-section ptb-100">
    <div class="container">
        <div class="section-title sec_title-two">
            <span>Our services</span>
            <h2>Professional services</h2>
            <p>We help agencies to define their new business objectives and then create the road map</p>
        </div>
        <div class="row" *ngIf="serviceData">
            
            <!-- single dervices -->
            <div class="col-lg-4 col-sm-6 col-xs-12 d-flex" *ngFor="let homepage of serviceData">
                <div class="single-service wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div class="service-icon">
                        <i class="{{ homepage.icon_name }}"></i>
                    </div>
                    <div class="service-inner">
                        <div class="service-content">
                            <h4>{{ homepage.title }}</h4>
                            <div [innerHTML]="homepage.description | removePTag"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- single dervices -->
            
        </div>
    </div>
</section>