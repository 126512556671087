<app-preloader></app-preloader>
<!-- header start -->
<header class="header-area header-top">
    <div class="top-header-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-8">
                    <ul class="header-content-right">
                        <li>
                            <i class="bx bx-phone-call"></i>
                            Call Us For Inquiry:
                            <a href="tel:+822456974">
                                01-5010070
                            </a>
                        </li>
                        <li>
                            <i class="bx bx-envelope"></i>
                            Email:
                            <a href="#">
                                <span class="__cf_email__" data-cfemail="">info@cosys.com.np</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6 col-md-4">
                    <ul class="header-content-left">
                        <li>
                            <a routerLink="https://www.facebook.com/cosyscooperative" target="_blank">
                                <i class="bx bxl-facebook"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="nav-area">
        <div class="navbar-area">
            <div class="mobile-nav">
                <div [routerLink]="'/home'" class="logo">
                    <img src="assets/images/pioneerlogo.png" alt="Logo" >
                </div>
                <!-- <div class="d-flex justify-content-sm-evenly w-100">
                    <div class="iso-logo">
                        <img src="https://pioneerassociate.com.np/assets/images/iso-logo.png" class="img-fluid" alt="">
                        <span>ISO 9001 : 2015 Accredited</span>
                    </div>
                    
                    <div class="mobile-nav-toggler" style="width: 20px; height: 20px; background-color: red;">
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                    </div>
                </div> -->
                <div class="iso-logo">
                    <img src="https://pioneerassociate.com.np/assets/images/iso-logo.png" class="img-fluid" alt="">
                    <span>ISO 9001 : 2015 Accredited</span>
                </div>
                <!--Mobile Navigation Toggler-->
                <div class="mobile-nav-toggler" (click)="toggleMobileMenu()" [class.active]="isMobileMenuVisible">
                    <i class="icon-bar"></i>
                    <i class="icon-bar"></i>
                    <i class="icon-bar"></i>
                </div>
            </div>
            <div class="menu-area clearfix">
                <nav class="main-menu navbar navbar-expand-md navbar-light">
                    <div class="container">
                        <div class="navbar-brand" [routerLink]="'/home'" style="cursor: pointer;">
                            <img id="imglogo" src="assets/images/pioneer-logo.png" alt="Logo"  >

                        </div>
                        <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                            <ul class="navigation navbar-nav clearfix">
                                <li [class.current]="activeMenuItem === 'home'">
                                    <a routerLink="/home" routerLinkActive="active">Home</a>
                                </li> 
                                <li [class.current]="activeMenuItem === 'about'">
                                    <a routerLink="/about" routerLinkActive="active">About Us</a>
                                </li>
                                <li [class.current]="activeMenuItem === 'services'">
                                    <a routerLink="/services" routerLinkActive="active">Services</a>
                                </li>
                                <li [class.current]="activeMenuItem === 'portfolio'">
                                    <a routerLink="/portfolio" routerLinkActive="active">Portfolio</a>
                                </li>
                                <li [class.current]="activeMenuItem === 'contact'">
                                    <a routerLink="/contact" routerLinkActive="active">Contact</a>
                                </li>
                                <li [class.current]="activeMenuItem === 'hierarchy'">
                                    <a routerLink="/hierarchy" routerLinkActive="active">Hierarchy</a>
                                </li>
                            </ul>
                        </div>
                        <div class="iso-logo">
                            <img src="assets/images/iso-logo.png" class="img-fluid" alt="">
                            <span>ISO 9001 : 2015 Accredited</span>
                        </div>
                    </div>
                </nav>
                        
            </div>
        </div>
    </div>
</header>

<!-- Mobile Menu  -->
<!-- <app-mobile-menu *ngIf="isMobileMenuVisible"></app-mobile-menu> -->
<app-mobile-menu [mobileMenuContent]="mobileMenuContent"></app-mobile-menu>
