<!-- Start Breadcum Area -->
<div class="breadcrumb-area" style="background-image: url('assets/images/banner/title.jpg');background-size:cover;">
  <div class="container">
      <div class="row">
          <div class="col-xl-7 col-lg-8 col-sm-10 breadcrumb-content">
              <span>Company Overview</span>
              <h2>We are 100%Trusted IT Solution Company</h2>
              <ul>
                  <li><a href="https://pioneerassociate.com.np">Home</a></li>
                  <li>About Us</li>
              </ul>
          </div>
      </div>
  </div>
</div>
<!-- End Breadcum Area -->

<!-- Start About Area -->
<section class="company-area">
  <div class="container" *ngIf="aboutUsData">
      <div class="row align-items-center" *ngFor="let aboutus of aboutUsData">
          <div class="col-lg-6 col-md-12">
              <div class="company-content wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                  <div class="company-content" >

                    <!-- <span>About Us</span> -->
                    <h2>{{ aboutus.title }}</h2>
                    <div class="res-company-img my-4">
                        <img src="{{imageUrl}}/aboutus_images/{{ aboutus.image }}" alt="company">
                    </div>
                    <div [innerHTML]="aboutus.introduction | removePTag"></div>
                                        
                  </div>
              </div>
          </div>
          <div class="col-lg-6 col-md-12">
              <div class="company-img">
                  <img src="{{imageUrl}}/aboutus_images/{{ aboutus.image }}" alt="company">
              </div>
          </div>
      </div>
  </div>
</section>

<!-- End About Area -->

<app-our-mission></app-our-mission>

<!-- <app-api-post-data></app-api-post-data> -->