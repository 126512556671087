<!-- Hero Banner Section -->
<app-main-banner></app-main-banner>
<!-- Hero Banner Section End -->

<!-- Featured Tab Section -->
<app-home-tab></app-home-tab>
<!-- Featured Tab Section End -->

<!-- Support Section -->
<app-support-service></app-support-service>
<!-- Support Section end -->

<!-- About Section -->
<app-about-area></app-about-area>
<!-- About Section End -->

<!-- Our Services Section Start -->
<app-home-service></app-home-service>
<!-- Our Services Section End -->