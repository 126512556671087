<footer class="footer">
    <div class="footer-area">
        <div class="container">
            <div class="row">
                <div class="col-md-5 col-sm-5 col-xs-12">
                    <div class="footer-content logo-footer">
                        <div class="footer-head">
                            <div class="footer-logo">
                                <div [routerLink]="'/home'" style="cursor: pointer;"><img src="assets/images/pioneer-logo.png" alt="Pioneer Logo"></div>
                            </div>
                            <p>
                                Pioneer Associate mission is to help our customers be more productive and efficient by offering professional and affordable Web Design services to compliment the client's marketing and cost containment.
                            </p>
                        </div>
                    </div>
                </div>
                <!-- end single footer -->
                <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                    <div class="footer-content">
                        <div class="footer-head">
                            <h4>Links</h4>
                            <ul class="footer-list">
                                <li><a routerLink="/about">About Us</a></li>
                                <li><a routerLink="/services">Our services </a></li>
                            </ul>
                            <ul class="footer-list hidden-sm">
                                <li><a routerLink="/contact">Contact</a></li>
                                <li><a routerLink="/portfolio">Portfolio</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- end single footer -->
                <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                    <div class="footer-content last-content">
                        <div class="footer-head">
                            <h4>Information</h4>
                            <div class="footer-contacts">
                                <p><span>Location :</span> National Co-Operative Federation Nepal(NCFN) Building, 4th Floor, Harihar Bhawan, Pulchowk, Lalitpur, Nepal</p>
                                <p><span>Tel :</span> 00977-01-5010070,00977-01-5010077, 00977-01-5010300, 00977-01-5010310</p>
                                <p><span>Email :</span> info@cosys.com.np<br>info@pioneerassociate.com.np</p>
                            </div> 
                            <div class="footer-icons">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/cosyscooperative" target="_blank">
                                            <i class="bx bxl-facebook"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Start Footer Bottom Area -->
    <div class="footer-area-bottom">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="copyright">
                        <p>
                            Copyright © 2023 <b>Pioneer Associate</b>. All Rights Reserved
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <ul>
                        <li>
                            <a routerLink="/privacy-policy">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- End Footer Bottom Area -->
</footer>