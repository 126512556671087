
<!-- Mobile Menu -->
<div class="mobile-menu">
    <div class="menu-backdrop" (click)="hideMobileMenu()"></div>
    <div class="close-btn" (click)="hideMobileMenu()"><i class="bx bx-x"></i></div>
  
    <nav class="menu-box">
      <!-- Menu content will be dynamically added here -->
      <div class="menu-outer" [innerHTML]="mobileMenuContent"></div>
    </nav>
</div>
