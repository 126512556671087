<section class="hero-slider-area">
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
            
                <div class="slider-item slider-item-bg-1" [ngStyle]="{backgroundImage:'url(../../../assets/images/slider/slider-5.jpg)'}">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="slider-text one">
                                    <span class="top-title">Welcome to Pioneer Associate</span>
                                    <h1>Helping finance institutions go digital</h1>
                                    <p>Providing organizations with IT solutions that make operations more productive, efficient and agile.</p>
                                    <div class="slider-btn">
                                        <a class="default-btn btn-theme" href="/contact">
                                            Contact
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
        </ng-template>
        <ng-template carouselSlide>
          
            <div class="slider-item slider-item-bg-2" [ngStyle]="{backgroundImage:'url(../../../assets/images/slider/slider-6.jpg)'}">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="slider-text one">
                                <span class="top-title">Welcome to Pioneer Associate</span>
                                <h1>Keep Your Business Safe With Our Services</h1>
                                <p>With our Fintech/Digital Solutions, we're assisting financial institutions in going digital.</p>
                                <div class="slider-btn">
                                    <a class="default-btn btn-theme" href="/contact">
                                        Contact
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </ng-template>
    </owl-carousel-o>
</section>