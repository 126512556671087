<section class="get-quat-area pb-100">
    <div class="container">
        <div class="tab get-quat-list-tab wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <ul class="tabs">
                        <li class="single-get-list" (click)="showTab(0)">
                            <i class="bx bx-globe"></i>
                            <span>Advance Web Technology</span>
                        </li>
                        <li class="single-get-list" (click)="showTab(1)">
                            <i class="bx bx-code-alt"></i>
                            <span>High-End Solution</span>
                        </li>
                        <li class="single-get-list" (click)="showTab(2)">
                            <i class="bx bx-ruler"></i>
                            <span>Scalability and Reliability</span>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-4">
                    <div class="tab_content">
                        <div class="tabs_item" *ngIf="isTabActive(0)">
                            <div class="get-quat-img">
                                <img src="assets/images/insurance/insurance-1.jpg" alt="Image">
                            </div>
                        </div>
                        <div class="tabs_item" *ngIf="isTabActive(1)">
                            <div class="get-quat-img">
                                <img src="assets/images/insurance/insurance-2.jpg" alt="Image">
                            </div>
                        </div>
                        <div class="tabs_item" *ngIf="isTabActive(2)">
                            <div class="get-quat-img">
                                <img src="assets/images/insurance/insurance-3.jpg" alt="Image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="tab_content">
                        <div class="tabs_item" *ngIf="isTabActive(0)">
                            <div class="get-quat-content">
                                <h3>Our Core Technologies</h3>
                                <ul>
                                    <li><i class="pi pi-check"></i> VB.Net</li>
                                    <li><i class="pi pi-check"></i> ASP.Net</li>
                                    <li><i class="pi pi-check"></i> Dot Net Nuke</li>
                                    <li><i class="pi pi-check"></i> C#.Net</li>
                                    <li><i class="pi pi-check"></i> E-Commerce Solutions</li>
                                    <li><i class="pi pi-check"></i> Data warehousing</li>
                                    <li><i class="pi pi-check"></i> OS Commerce Experties</li>
                                    <li><i class="pi pi-check"></i> Software/Web Quality Assurance</li>
                                </ul>
                                
                            </div>
                        </div>
                        <div class="tabs_item" *ngIf="isTabActive(1)">
                            <div class="get-quat-content">
                                <h3>High-End Solution</h3>
                                <ul>
                                    <li><i class="pi pi-check"></i> Offshore Development</li>
                                    <li><i class="pi pi-check"></i> Payroll Processing</li>
                                    <li><i class="pi pi-check"></i> Online/Offline Data Entry</li>
                                    <li><i class="pi pi-check"></i> IT Outsourcing</li>
                                    <li><i class="pi pi-check"></i> Offshore Accounting</li>
                                    <li><i class="pi pi-check"></i> Web Development</li>
                                    <li><i class="pi pi-check"></i> Database Administration</li>
                                    <li><i class="pi pi-check"></i> Brochure Design</li>
                                </ul>
                                
                            </div>
                        </div>
                        <div class="tabs_item" *ngIf="isTabActive(2)">
                            <div class="get-quat-content">
                                <h3>Scalability and Reliability</h3>
                                <ul>
                                    <li><i class="pi pi-check"></i> Domain Registration NRs. 2,000/-</li>
                                    <li><i class="pi pi-check"></i> 10 GB Web Space NRs. 9,000/-</li>
                                    <li><i class="pi pi-check"></i> 20 GB Web Space NRs. 15,000/-</li>
                                    <li><i class="pi pi-check"></i> 40 GB Web Space NRs. 25,000/-</li>
                                    <li><i class="pi pi-check"></i> 80 GB Web Space NRs. 40,000/-</li>
                                    <li><i class="pi pi-check"></i> 100 GB Web Space NRs. 55,000/-</li>
                                    <li><i class="pi pi-check"></i> 200 GB Web Space NRs. 80,000/-</li>
                                    <li><i class="pi pi-check"></i> 1000 GB Web Space NRs. 2,50,000/-</li>
                                </ul>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
