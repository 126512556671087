import { Component } from '@angular/core';

@Component({
  selector: 'app-support-service',
  templateUrl: './support-service.component.html',
  styleUrls: ['./support-service.component.css']
})
export class SupportServiceComponent {

}
