<!-- Start Breadcum Area -->
<div class="breadcrumb-area" style="background-image: url('assets/images/banner/title.jpg');background-size:cover;">
  <div class="container">
      <div class="row">
          <div class="col-xl-7 col-lg-8 col-sm-10 breadcrumb-content">
              <span>Our Team</span>
              <!-- <h2>We are 100%Trusted IT Solution Company</h2> -->
              <ul>
                  <li><a href="https://pioneerassociate.com.np">Home</a></li>
                  <li>Team</li>
              </ul>
          </div>
      </div>
  </div>
</div>
<!-- End Breadcum Area -->
  
<!-- Our Portfolio Start -->
<section class="ptb-100">
  <div class="container">
      <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 d-flex">
              <img src="assets/images/chart.png" alt="/" class="img-fluid">
          </div>
      </div>
  </div>
</section>
<!-- Our Portfolio End -->

<!-- Start team Area -->
<!-- <section class="team-section">
    <div class="container">
        <div class="row align-items-center">
          
            <div class="{{teamMember.category !=='HOD'? 'col-lg-12 col-sm-6':'col-lg-4 col-md-6 col-sm-6'}} " *ngFor="let teamMember of ourTeamData">
                <div class="single-team inner-box">
                  <div class="image">
                    <img src="{{imageUrl}}/team_images/{{ teamMember.image }}" alt="Team Member">
                    
                    <ul class="social">
                        <li>
                            <a href="#" target="_blank">
                                <i class="bx bxl-facebook"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class="bx bxl-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class="bx bxl-linkedin"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class="bx bxl-instagram"></i>
                            </a>
                        </li>
                    </ul>
                  </div>
                  
                  <div class="content">
                    <h3>{{ teamMember.title }}</h3>
                    <span>{{ teamMember.position }}</span>
                  </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Team Area -->
