<section class="get-quat-area pb-100">
    <div class="container">
        <div class="tab get-quat-list-tab wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <ul class="tabs">
                    <li class="single-get-list" (click)="showTab(0)">
                        <i class="bx bx-globe"></i>
                        <span>Advance Web Technology</span>
                    </li>
                    <li class="single-get-list" (click)="showTab(1)">
                        <i class="bx bx-code-alt"></i>
                        <span>High-End Solution</span>
                    </li>
                    <li class="single-get-list" (click)="showTab(2)">
                        <i class="bx bx-ruler"></i>
                        <span>Scalability and Reliability</span>
                    </li>
                    </ul>
                </div>
                <div class="col-lg-4">
                    <div class="tab_content">
                    <div class="tabs_item" [hidden]="!isTabActive(0)">
                        <div class="get-quat-img">
                        <img src="assets/images/insurance/web-Technology.jpg" alt="Image">
                        </div>
                    </div>
                    <div class="tabs_item" [hidden]="!isTabActive(1)">
                        <div class="get-quat-img">
                        <img src="assets/images/insurance/insurance-2.jpg" alt="Image">
                        </div>
                    </div>
                    <div class="tabs_item" [hidden]="!isTabActive(2)">
                        <div class="get-quat-img">
                        <img src="assets/images/insurance/insurance-3.jpg" alt="Image">
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="tab_content">
                        <div class="tabs_item" [hidden]="!isTabActive(0)">
                            <div class="get-quat-content">
                            <h3>Our Core Technologies</h3>
                            <ul>
                                <li>VB.Net</li>
                                <li>ASP.Net</li>
                                <li>Dot Net Nuke</li>
                                <li>C#.Net</li>
                                <li>E-Commerce Solutions</li>
                                <li>Data warehousing</li>
                                <li>OS Commerce Experties</li>
                                <li>Software/Web Quality Assurance</li>
                            </ul>
                            <a href="#" class="default-btn btn-theme mt-3">
                                More
                            </a>
                            </div>
                        </div>
                        <div class="tabs_item" [hidden]="!isTabActive(1)">
                            <div class="get-quat-content">
                            <h3>High-End Solution</h3>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quia ipsum laudantium voluptatum error inventore
                                alias aperiam reprehenderit, distinctio sapiente modi. Atque mollitia voluptas aut velit obcaecati tempora!
                                Et, repudiandae exercitationem?
                            </p>
                            <a href="#" class="default-btn btn-theme">
                                Get A Quat
                            </a>
                            </div>
                        </div>
                        <div class="tabs_item" [hidden]="!isTabActive(2)">
                            <div class="get-quat-content">
                            <h3>Scalability and Reliability</h3>
                            <p>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quia ipsum laudantium voluptatum error inventore
                                alias aperiam reprehenderit, distinctio sapiente modi. Atque mollitia voluptas aut velit obcaecati tempora!
                                Et, repudiandae exercitationem?
                            </p>
                            <a href="#" class="default-btn btn-theme">
                                Get A Quat
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  