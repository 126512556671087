<!-- Start Breadcum Area -->
<div class="breadcrumb-area" style="background-image: url('assets/images/banner/title.jpg');background-size:cover;">
    <div class="container">
        <div class="row">
            <div class="col-xl-7 col-lg-8 col-sm-10 breadcrumb-content">
                <span>Privacy &amp; Policy</span>
                <h2>Our Company Privacy Policy</h2>
                <ul>
                    <li><a href="/home">Home</a></li>
                    <li>Privacy &amp; Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Breadcum Area -->

<!-- Start Privacy policy Area -->
<section class="terms-conditions ptb-100">
    <div class="container">
        <div class="single-privacy">
            <h2 class="mt-0 mb-3">Pioneer Associate Privacy &amp; Policy</h2>
            <p>
                <strong>Last updated September 7, 2021</strong>
                <br>
                Thank you for choosing to be part of our community at Pioneer Associate Pvt. Ltd. ("Company", "we", "us", "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at cosysapps@cosys.com.np. When you use our mobile application, as the case may be (the "App") and more generally, use any of our services (the "Services", which include the App), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately. This privacy notice applies to all information collected through our Services (which, as described above, includes our App), as well as, any related services, sales, marketing or events. Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.
            </p>
            
            <h3>WHAT INFORMATION DO WE COLLECT?</h3>

            <ul>
                <li>
                    <span><strong>01. Information automatically collected:</strong></span>
                    <p>In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our App. We automatically collect certain information when you visit, use or navigate the App. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our App and other technical information. This information is primarily needed to maintain the security and operation of our App, and for our internal analytics and reporting purposes.</p>
                </li>
                <li>
                    <span><strong>02. The information we collect includes:</strong></span>
                    <p>Log and Usage Data: Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our App and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the App (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).</p>
                </li>
                <li>
                    <span><strong>03. Information collected through our App:</strong></span>
                    <p>In Short: We collect information regarding your mobile device, push notifications, when you use our App.</p>
                </li>
                <li>
                    <span><strong>04. If you use our App, we also collect the following information:</strong></span>
                    <p>Mobile Device Access: We may request access or permission to certain features from your mobile device, including your mobile device's camera, contacts, storage, and other features. If you wish to change our access or permissions, you may do so in your device's settings. Mobile Device Data: We automatically collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our App, we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID and information about the features of our App you accessed. Push Notifications: We may request to send you push notifications regarding your account or certain features of the App. If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings. This information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.</p>
                </li>
            </ul>
            <h3>HOW DO WE USE YOUR INFORMATION?</h3>
            <p>In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent. We use personal information collected via our App for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below. We may use your information for other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our App, products, marketing and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use identifiable personal information without your consent.</p>
            <h3>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>
            <p>In Short: We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations. We may process or share your data that we hold based on the following legal basis:</p>
            <ul>
                <li>
                    <p><strong>01.</strong> Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.</p>
                </li>
                <li>
                    <p><strong>02.</strong> Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</p>
                </li>
                <li>
                    <p><strong>03.</strong> Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</p>
                </li>
                <li>
                    <p><strong>04.</strong> Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</p>
                </li>
                <li>
                    <p><strong>05.</strong> Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</p>
                </li>
            </ul>
            <p>More specifically, we may need to process your data or share your personal information in the following situations: Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
            <h3>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h3>
            <p>In Short: We may transfer, store, and process your information in countries other than your own. Our servers are located in Nepal. If you are accessing our App from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WILL YOUR INFORMATION BE SHARED WITH ANYONE?" above), in and other countries.</p>
            <h3>HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
            <p>In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law. We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than 2 years. When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
            <h3>HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
            <p>In Short: We aim to protect your personal information through a system of organizational and technical security measures. We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our App is at your own risk. You should only access the App within a secure environment.</p>
            <h3>DO WE COLLECT INFORMATION FROM MINORS?</h3>
            <p>In Short: We do not knowingly collect data from or market to children under 18 years of age. We do not knowingly solicit data from or market to children under 18 years of age. By using the App, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the App. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at cosysapps@cosys.com.np.</p>
            <h3>CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
            <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
            <h3>DO WE MAKE UPDATES TO THIS NOTICE?</h3>
            <p>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws. We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
            <h3>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
            <p>If you have questions or comments about this notice, you may email us at cosysapps@cosys.com.np or by post to: Pioneer Associate Pvt. Ltd. Hariharbhawan, Lalitpur, Bagmati Pradesh 44600, Nepal</p>
            <h3>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
            <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by clicking here. We will respond to your request within 30 days.</p>
            
        </div>
    </div>
</section>
<!-- End Privacy policy Area -->