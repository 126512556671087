<section class="commitment-area">
    <div class="container" *ngIf="aboutUsData && aboutUsData.length > 0">
        <div class="row" *ngFor="let data of aboutUsData">
            <div class="col-lg-3 col-md-6 d-flex" >
                <div class="card-item wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div class="card-icon">
                        <i class="flaticon-clipboard"></i>
                    </div>
                    <div class="card-body">
                        <h3>Our Mission</h3>
                        <div [innerHTML]="data.mission | removePTag"></div>
                    </div>
                </div>
            </div>
  
            <div class="col-lg-3 col-md-6 d-flex">
                <div class="card-item wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div class="card-icon">
                        <i class="flaticon-idea"></i>
                    </div>
                    <div class="card-body">
                        <h3>Our Vision</h3>
                        <div [innerHTML]="data.vision | removePTag"></div>
                    </div>
                </div>
            </div>
  
            <div class="col-lg-3 col-md-6 d-flex">
                <div class="card-item wow fadeInDown" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div class="card-icon">
                        <i class="flaticon-value"></i>
                    </div>
                    <div class="card-body">
                        <h3>Core Values</h3>
                        <div [innerHTML]="data.core_values | removePTag"></div>
                        
                    </div>
                </div>
            </div>
  
            <div class="col-lg-3 col-md-6 d-flex">
                <div class="card-item  wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div class="card-icon">
                        <i class="flaticon-chart"></i>
                    </div>
                    <div class="card-body">
                        <h3>Goals</h3>
                        <div [innerHTML]="data.objective | removePTag"></div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
  </section>