<!-- Start Breadcum Area -->
<div class="breadcrumb-area" style="background-image: url('assets/images/banner/title.jpg');background-size:cover;">
  <div class="container">
      <div class="row">
          <div class="col-xl-7 col-lg-8 col-sm-10 breadcrumb-content">
              <span>Contact Us</span>
              <h2>Need information? contact us</h2>
              <ul>
                  <li><a href="https://pioneerassociate.com.np">Home</a></li>
                  <li>Contact Us</li>
              </ul>
          </div>
      </div>
  </div>
</div>
<!-- End Breadcum Area -->

<!-- Our Contact Info Start -->
<section class="contact-info-area ptb-100">
  <div class="container">
      <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6 d-flex">
              <div class="contact-info-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                  <div class="icon">
                      <i class="bx bx-envelope"></i>
                  </div>
                  <h3>Email Here</h3>
                  <p><a href="mailto:info@cosys.com.np">info@cosys.com.np</a></p>
                  <p><a href="mailto:info@pioneerassociate.com.np">info@pioneerassociate.com.np</a></p>
              </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6 d-flex">
              <div class="contact-info-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                  <div class="icon">
                      <i class="bx bx-been-here"></i>
                  </div>
                  <h3>Location Here</h3>
                  <p>Pioneer Associate Pvt. Ltd. National Co-Operative Federation Nepal(NCFN) Building, 4th Floor, Harihar Bhawan, Pulchowk, Lalitpur, Nepal</p>
              </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 d-flex">
              <div class="contact-info-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                  <div class="icon">
                      <i class="bx bx-phone-call"></i>
                  </div>
                  <h3>Call Here</h3>
                  <p><a href="tel:01-5010310">01-5010310</a></p>
                  <p><a href="tel:01-5010070">01-5010070</a></p>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- Our Contact Info End -->

<!-- Map Start -->
<section class="map-section">

  <div class="contact-info-block">
      <div class="inner wow fadeInUp animated" data-wow-delay="0ms" data-wow-duration="1500ms"
          style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInUp;">
          <div class="content-box" style="line-height: 1;">
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14132.908363755869!2d85.3151501!3d27.6793753!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbbfea3476e98cf0!2sPioneer%20Associate%20Pvt.%20Ltd!5e0!3m2!1sen!2snp!4v1627817176978!5m2!1sen!2snp"
                  width="100%" height="445" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
      </div>
  </div>

</section>
<!-- Map End -->