<section class="about_area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="column-inner-1 col-lg-6 col-md-6 col-xs-12 col-md-12">
                <div class="column-1-inner position-relative">
                    <h1 class="start-up">Start up your New Career Ventures With <span>Pioneer Associate</span></h1>
                    <p class="para"><em class="theme-red">Pioneer Associate</em> mission is to help our customers be more productive and efficient by offering professional and affordable Web Design services to compliment the client's marketing and cost containment.
                    </p>
                    <div class="row">
                        <div class="col-lg-12 col-md-4 col-sm-6 col-xs-12">
                            <h6><a href="#">Discover</a></h6>
                        </div>
                       

                    </div>
                </div>
            </div>
            <div class="column-inner-2 col-lg-6 col-md-6 col-xs-12 col-md-12">
                <div class=" column-2-inner position-relative">
                    <div class="text-inner-1">
                        <div class="icon">
                            <a href="#"><span class="bx bx-globe icon"></span></a>
                        </div>
                        <div class="text-left">
                            <h2> <a href="#">A Global Network</a></h2>
                            <p>That empathy of business ownership creates the relationship they required to actually make a difference.</p>
                        </div>
                    </div>
                    <div class="text-inner-1">
                        <div class="icon">
                            <a href="#"> <span class="bx bx-bulb icon"></span></a>
                        </div>
                        <div class="text-left" style="padding-left: 0px;">
                            <h2><a href="#">Commitment to Innovation</a> </h2>
                            <p>Over 20 years we have been advising a diverse range of businesses on how they can find a competitive advantage.</p>
                        </div>
                    </div>
                    <div class="text-inner-1">
                        <div class="icon">
                            <a href="#"> <span class="bx bx-accessibility icon"></span></a>
                        </div>
                        <div class="text-left">
                            <h2><a href="#">Building Trusted Partnership </a></h2>
                            <p>We partner with clients to put recommendations into practice and work directly with them over the long-term.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>