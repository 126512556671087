<!-- Start Breadcum Area -->
<div class="breadcrumb-area" style="background-image: url('assets/images/banner/title.jpg');background-size:cover;">
  <div class="container">
      <div class="row">
          <div class="col-xl-7 col-lg-8 col-sm-10 breadcrumb-content">
              <span>Solutions</span>
              <h2>Provide All Kind Of Tech Solutions</h2>
              <ul>
                  <li><a href="/home">Home</a></li>
                  <li>Solutions</li>
              </ul>
          </div>
      </div>
  </div>
</div>
<!-- End Breadcum Area -->

<!-- Our Services Start -->
<section class="our-services-section ptb-100">
  <div class="container">
      <div class="row">
          
          <!-- single dervices -->
          <div class="col-lg-4 col-sm-6 col-xs-12 d-flex" *ngFor="let ourservice of serviceData">
              <div class="single-service wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                  <div class="service-icon">
                    <i class="{{ ourservice.icon_name }}"></i>
                  </div>
                  <div class="service-inner">
                      <div class="service-content">
                          <h4>{{ ourservice.title }}</h4>
                          <div [innerHTML]="ourservice.description | removePTag"></div>
                          
                      </div>
                  </div>
              </div>
          </div>
          <!-- single dervices -->
      </div>
  </div>
</section>
<!-- Our Services End -->