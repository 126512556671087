<!-- Start Breadcum Area -->
<div class="breadcrumb-area" style="background-image: url('assets/images/banner/title.jpg');background-size:cover;">
    <div class="container">
        <div class="row">
            <div class="col-xl-7 col-lg-8 col-sm-10 breadcrumb-content">
                <span>Portfolio</span>
                <h2>Provide All Kind Of Tech Solutions</h2>
                <ul>
                    <li><a href="/home">Home</a></li>
                    <li>Portfolio</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Breadcum Area -->

<!-- Our Portfolio Start -->
<section class="ptb-100">
  <div class="container">
    <div class="row">
        
      <div class="col-lg-4 col-sm-6 col-xs-12 preview-box wow fadeInLeft" data-wow-delay="0.1s" data-wow-duration="1500ms" *ngFor="let portfolio of portfolioData">
          <div class="preview-item image-with-text">                        
              <div class="preview-image">
                <img src="{{imageUrl}}/portfolio/{{portfolio.image}}" alt="/" class="img-fluid">
              </div>
                                              
              <div class="hover-content">
                <div class="hover-inner-content">
                  <h5 class="hover-title"><a href="{{portfolio.url}}" target="_blank">{{ portfolio.site_name }} <i class="bx bx-link-alt"></i></a></h5>
                </div>
              </div>
          </div><!-- End Preview box -->
      </div>
    </div>
  </div>
</section>
<!-- Our Portfolio End -->
